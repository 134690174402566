import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/greetings/Konnichiwa.mp3';
import sound2 from '../sound/greetings/Ohayou-gozaimasu.mp3';
import sound3 from '../sound/greetings/Konbanwa.mp3';
import sound4 from '../sound/greetings/Oyasuminasai.mp3';
import sound5 from '../sound/greetings/Sayounara.mp3';
import sound6 from '../sound/greetings/Hajimemashite.mp3';
import sound7 from '../sound/greetings/Ogenki-desu-ka.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';
import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Konnichiwa", translation: 'Hello', kanji: "こんにちは", sound: sound1 },
  { phrase: "Ohayō gozaimasu", translation: 'Good morning', kanji: "おはようございます", sound: sound2 },
  { phrase: "Konbanwa", translation: 'Good evening', kanji: "こんばんは", sound: sound3 },
  { phrase: "Oyasuminasai", translation: 'Good night', kanji: "おやすみなさい", sound: sound4 },
  { phrase: "Sayōnara", translation: 'Goodbye', kanji: "さようなら", sound: sound5 },
  { phrase: "Hajimemashite", translation: 'Nice to meet you', kanji: "はじめまして", sound: sound6 },
  { phrase: "Ogenki desu ka?", translation: 'How are you?', kanji: "お元気ですか？", sound: sound7 },
];

const Greetings = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

    // Add the 'no-scroll' class to the html element
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the html element on component unmount
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Greetings" />
      </Header>

      {sections.map((section, index) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Greetings;