import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/emergency/Tasukete-kudasai.mp3';
import sound2 from '../sound/emergency/Keisatsu-o-yonde-kudasai.mp3';
import sound3 from '../sound/emergency/Isha-o-yonde-kudasai.mp3';
import sound4 from '../sound/emergency/Kaji-desu.mp3';
import sound5 from '../sound/emergency/Byouin-wa-doko-desu-ka.mp3';
import sound6 from '../sound/emergency/Guai-ga-warui-desu.mp3';
import sound7 from '../sound/emergency/Hokenshou-o-misete-kudasai.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';
import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Tasukete kudasai!", translation: 'Help!', kanji: "助けてください！", sound: sound1 },
  { phrase: "Keisatsu o yonde kudasai", translation: 'Call the police, please', kanji: "警察を呼んでください", sound: sound2 },
  { phrase: "Isha o yonde kudasai", translation: 'Call a doctor, please', kanji: "医者を呼んでください", sound: sound3 },
  { phrase: "Kaji desu!", translation: 'Fire!', kanji: "火事です！", sound: sound4 },
  { phrase: "Byōin wa doko desu ka?", translation: 'Where is the hospital?', kanji: "病院はどこですか？", sound: sound5 },
  { phrase: "Guai ga warui desu", translation: 'I feel sick', kanji: "具合が悪いです", sound: sound6 },
  { phrase: "Hokenshō o misete kudasai", translation: 'Please show your insurance card', kanji: "保険証を見せてください", sound: sound7 },
];

const Emergency = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

    // Add the 'no-scroll' class to the html element
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the html element on component unmount
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    };
  }, []);


  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Emergency" />
      </Header>

      {sections.map((section) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Emergency;