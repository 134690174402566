import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Logo from './logo';
import Stack from './stack';
import Button from './button';

interface HeaderProps {
  children?: React.ReactNode;
  onClick?: () => void;
  tagline?: boolean;
}

const HeaderFixedStyles = styled.div({
  position: 'fixed',
  top: 0,
  width: '100%',
});

const HeaderStyles = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 14px 16px 24px',
  borderColor: 'light-dark(var(--surface-dark), var(--surface-light))',
  background: 'light-dark(var(--light), var(--dark));',
  borderBottom: '1px solid light-dark(var(--green-200), var(--green-800))',
  '@media (min-width: 640px)': {
    padding: '16px 16px 16px 36px',
  },
});

const HeaderInnerStyles = styled(Stack)({
  alignItems: 'center',
  gap: 16,
  '@media (min-width: 640px)': {
    gap: 24,
  },
});

const TaglineStyles = styled.span({
  fontFamily: '"Aleo", serif',
  fontWeight: 700,
  'font-optical-sizing': 'auto',
  fontStyle: 'italic',
  display: 'none',
  color: 'light-dark(var(--green-1000), var(--green-050))',
  '@media (min-width: 640px)': {
    display: 'flex',
    alignItems: 'center'
  },
});

const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  display: 'flex'
}));

const Header: React.FC<HeaderProps> = ({ children, tagline, onClick }) => {
  return (
      <HeaderFixedStyles>
        <HeaderStyles>
        <HeaderInnerStyles direction='horizontal'>
          <StyledLink to="/"><Logo /></StyledLink>
          {tagline && <TaglineStyles>Master Japanese Phrases</TaglineStyles>}
        </HeaderInnerStyles>
        <Stack direction='horizontal'>
          <Button variant='tertiary' size="S" onClick={onClick}>Categories</Button>
        </Stack>
        </HeaderStyles>
        {children}
      </HeaderFixedStyles>
  );
};




export default Header;