/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import variantAction from './helpers/variant-action';
import sizeActionStyles from './helpers/size-actions';
import baseAction from './helpers/base-action';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'default';
  size?: 'S' | 'M' | 'L';
  onClick?: () => void;
  children: React.ReactNode;
  width?: string; // Add width prop
  height?: string; // Add height prop
  margin?: string; // Add margin prop
  style?: React.CSSProperties; // Add style prop for additional styles
}

const getButtonStyles = (
  variant: 'primary' | 'secondary' | 'tertiary' | 'default', 
  size: 'S' | 'M' | 'L', 
  style?: React.CSSProperties
) => {
  const baseStyle = {
    ...baseAction,
    ...sizeActionStyles[size],
    ...variantAction[variant],
    ...style,
  };

  const hoverStyle = variantAction[variant].hover;

  return css({
    ...baseStyle,
    ':hover': hoverStyle,
  });
};

const Button = styled.button<ButtonProps>`
  ${({ variant = 'primary', size = 'M', width, height, margin, style }) => css`
    ${getButtonStyles(variant, size, style)}
    width: ${width || 'auto'};
    height: ${height || 'auto'};
    margin: ${margin || '0'};
  `}
`;

export default Button;