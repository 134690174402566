/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import Stack from '../components/stack';
import Heading from '../components/heading';
import { ReactNode } from 'react';
import Responsive from '../components/responsive';
import AudioController from '../components/audioplayer';

interface ActivityCardProps {
  phrase: string;
  translation: string;
  kanji: string;
  imageUrl?: string;
  actions?: ReactNode;
  onClick?: () => void;
  css?: SerializedStyles; // Add this line
  source?: string;
}

const cardStyle = css`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  padding: 24px 24px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  @media (min-width: 640px) {
    padding: 48px 24px;
  }
`;

const actionsStyle = css`
  justify-content: center;
  margin-top: 12px;
  @media (min-width:500px) {
    margin-top: 48px;
  }
`;

const imageOuterStyle = css`
  width: 240px;
  height: 240px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const imageStyle = css`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 100%;
  margin-bottom: 24px;
`;

const japaneseTextStyle = css`
  color: light-dark(var(--green-500), var(--green-100));
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 400; 
  font-style: normal;
`;

const translationTextStyle = css`
  color: light-dark(var(--green-500), var(--green-100));
  font-weight: 400;
  font-style: italic;
`;

const phraseTextStyle = css`
  color: light-dark(var(--green-1000), var(--green-050));
  word-break: break-word;
  @media (min-width: 350px) {
    word-break: unset;
  }
`;

const Activity: React.FC<ActivityCardProps> = ({ kanji, phrase, translation, actions, imageUrl, source, css: additionalCss }) => {
  return (
    <Stack css={css([cardStyle, additionalCss])}>
      {imageUrl && 
        <Stack css={imageOuterStyle}>
          <img src={imageUrl} alt={phrase} css={imageStyle} />
        </Stack>
      }

      <Responsive below={499}>
        <Stack variant='grid' spacing="16px">
          <Heading level="6" css={translationTextStyle}>"{translation}"</Heading>
          <Heading level="3" css={phraseTextStyle}>{phrase}</Heading>
          <Heading level="6" css={japaneseTextStyle}>{kanji}</Heading>
          {source && <AudioController source={source} />}
          {actions && <Stack direction='horizontal' spacing="24px" css={actionsStyle}>{actions}</Stack>}
        </Stack>
      </Responsive>       


      <Responsive above={500}>
        <Stack variant='grid' spacing="24px">
          <Heading level="5" css={translationTextStyle}>"{translation}"</Heading>
          <Heading level="1" css={phraseTextStyle}>{phrase}</Heading>
          <Heading level="5" css={japaneseTextStyle}>{kanji}</Heading>
          {source && <AudioController source={source} />}
          {actions && <Stack direction='horizontal' spacing="24px" css={actionsStyle}>{actions}</Stack>}
        </Stack>
      </Responsive>


    </Stack>
  );
};

export default Activity;