import React from 'react';

const Speaker: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="24"
    height="24"
  >
    <path d="M12.4 19.5c-.2 0-.3-.1-.5-.2l-4.7-4H3c-.4 0-.7-.3-.7-.7V9.3c0-.4.3-.7.7-.7h4.3l4.6-4c.2-.2.5-.2.7-.1.2.1.4.4.4.6v13.7c0 .3-.2.5-.4.6 0 .1-.1.1-.2.1zM18.6 19.5c-.2 0-.3-.1-.4-.2-.3-.2-.3-.7-.1-1 1.4-1.8 2.2-4 2.2-6.3s-.8-4.6-2.2-6.3c-.2-.3-.2-.7.1-1 .3-.2.7-.2 1 .1 1.6 2 2.5 4.6 2.5 7.2 0 2.6-.9 5.2-2.5 7.2-.2.2-.4.3-.6.3zm-1.9-1.8c-.1 0-.3 0-.4-.1-.3-.2-.4-.7-.1-1 1-1.3 1.5-2.9 1.5-4.6 0-1.7-.5-3.2-1.5-4.6-.2-.3-.2-.7.1-1 .3-.2.7-.2 1 .1 1.2 1.6 1.8 3.4 1.8 5.4s-.6 3.8-1.8 5.4c-.1.3-.3.4-.6.4zm-1.9-2c-.1 0-.3 0-.4-.1-.3-.2-.4-.6-.2-1 1-1.6 1-3.6 0-5.2-.2-.3-.1-.8.2-1 .3-.2.8-.1 1 .2 1.3 2 1.3 4.7 0 6.7-.2.3-.4.4-.6.4z" />
  </svg>
);

export default Speaker;