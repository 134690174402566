/** @jsxImportSource @emotion/react */
import React from 'react';
import { css as emotionCss, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

interface StackProps {
  variant?: 'flex' | 'grid';
  direction?: 'vertical' | 'horizontal';
  spacing?: string; // Spacing between the elements
  children: React.ReactNode;
  css?: SerializedStyles; // Additional Emotion CSS styles
}

const flexStyles = (direction: 'vertical' | 'horizontal', spacing: string) => emotionCss`
  display: flex;
  flex-direction: ${direction === 'vertical' ? 'column' : 'row'};
  gap: ${spacing};
`;

const gridStyles = (direction: 'vertical' | 'horizontal', spacing: string) => emotionCss`
  display: grid;
  grid-auto-flow: ${direction === 'vertical' ? 'row' : 'column'};
  gap: ${spacing};
`;

const getStackStyles = (variant: 'flex' | 'grid', direction: 'vertical' | 'horizontal', spacing: string) => {
  return variant === 'flex' 
    ? flexStyles(direction, spacing) 
    : gridStyles(direction, spacing);
};

const StackInner = styled.div<StackProps>`
  ${({ variant = 'flex', direction = 'vertical', spacing = '0', css: cssProp }) => emotionCss`
    ${getStackStyles(variant, direction, spacing)}
    ${cssProp}
  `}
`;

const Stack: React.FC<StackProps> = (props) => {
  return <StackInner {...props}>{props.children}</StackInner>;
};

export default Stack;