import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import Speaker from './icons/speaker';
import Pause from './icons/pause';
import Button from './button';
import Stack from './stack';

// Define the props type
interface AudioControllerProps {
  source?: string;
}

// React component with props
const AudioController: React.FC<AudioControllerProps> = ({ source }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const handleAudioEnd = () => {
      setIsPlaying(false);
    };

    const currentAudioRef = audioRef.current;
    if (currentAudioRef) {
      currentAudioRef.addEventListener('ended', handleAudioEnd);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (currentAudioRef) {
        currentAudioRef.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, []);

  return (
    <Stack css={css`max-width: 300px; margin: 24px auto 0;`}>
      <audio ref={audioRef} src={source} />
      <Button size='S' variant='secondary' onClick={togglePlay}>
        <Stack direction='horizontal' spacing='8px' css={css`align-items: center`}>
          {/* Show speaker icon before playing */}
          <span style={{height: 16}}>Read Aloud</span>
          {isPlaying ? <Pause /> : <Speaker />}
        </Stack>
      </Button>
    </Stack>
  );
};

export default AudioController;