// src/components/Home.js
import { useState, useEffect } from 'react';
import ActivityCard from '../compositions/Activity';
import Header from '../components/header';
import Button from '../components/button';
import Stack from '../components/stack';
import Dialog from '../components/dialog';
import CategoryDialog from '../compositions/CategoryDialog';
import Responsive from '../components/responsive';
import Heading from '../components/heading';
import { css } from '@emotion/react';
import Body from '../components/body';
import List from '../components/list';
import { Offset } from '../components/helpers/offset';

const Offer = (): string[] => {
  return [
    'Travelling: From booking a hotel to asking for directions, our travel phrases will help you communicate effectively during your adventures in Japan.',
    'Dining Out: Learn how to order food, make reservations, and understand restaurant etiquette with ease.',
    'Shopping: Master the phrases needed to shop confidently, whether at a local market or a high-end store.',
    'Socialising: Engage in meaningful conversations, introduce yourself, and make friends with phrases tailored for social settings.',
    'Work and Business: Navigate the professional world with phrases that will help you in meetings, interviews, and everyday work interactions.'
  ];
};

const Usage = (): string[] => {
  return [
    'Explore Categories: Browse through our categorised phrases to find the ones that match your current needs.',
    'Learn and Practise: Each phrase comes with pronunciation guides and examples to help you understand and use them correctly.',
    'Apply in Real Life: Use the phrases in real-life situations to build your confidence and improve your language skills.',
  ];
};

const Choose = (): string[] => {
  return [
    'Practical Learning: We focus on phrases that you will actually use, making your learning experience relevant and effective.',
    'Easy to Navigate: Our user-friendly interface allows you to quickly find the phrases you need.',
    'Continuous Updates: We regularly update our content to include new phrases and categories, ensuring that you always have access to the latest and most useful information.',
  ];
};

const Home = () => {

  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);

  const categoriesToggleVisibility = () => {
    categoriesSetIsVisible(prevState => !prevState);
  };

  const [aboutIsVisible, aboutSetIsVisible] = useState(false);

  const aboutToggleVisibility = () => {
    aboutSetIsVisible(prevState => !prevState);
  };

  const OfferItems = Offer();
  const UsageItems = Usage();
  const ChooseItems = Choose();

  // Add or remove the no-scroll class based on dialog visibility
  useEffect(() => {
    if (categoriesIsVisible || aboutIsVisible) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    }
  }, [categoriesIsVisible, aboutIsVisible]);




  
  return (
    <Offset>
    
      <Header tagline onClick={categoriesToggleVisibility} />

      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      {aboutIsVisible && 
        <Dialog title="Master Japanese Phrases" onClick={aboutToggleVisibility}>

          <Stack css={css`max-width: 75ch; margin: 0 auto;`} spacing="60px">

            <Stack spacing="16px">
              <Heading level="5" css={css`color: light-dark(var(--green-1000), var(--green-050))`}>About Sensei</Heading>
              <Body>Welcome to Sensei, your go-to resource for mastering Japanese through real-life, situational phrases. Our mission is to help learners at all levels enhance their Japanese language skills by providing practical and contextually relevant phrases that you can use in everyday situations.</Body>
            </Stack>

            <Stack spacing="16px">
              <Heading level="5" css={css`color: light-dark(var(--green-1000), var(--green-050))`}>Our Purpose</Heading>
              <Body>Learning a new language can be challenging, especially when it comes to understanding and using phrases that are commonly spoken in different contexts. That’s where Sensei comes in. We focus on situational Japanese phrases to ensure that you can confidently navigate various scenarios, whether you’re travelling, working, or simply having a conversation with native speakers.</Body>
            </Stack>

            <Stack spacing="16px">
              <Heading level="5" css={css`color: light-dark(var(--green-1000), var(--green-050))`}>What We Offer</Heading>
              <Body>At Sensei, we categorise our phrases based on real-life situations, making it easy for you to find and learn what you need when you need it. Here’s how our categories are designed to help you:</Body>
              <List items={OfferItems} styleType="disc" ordered={true} />
            </Stack>

            <Stack spacing="16px">
              <Heading level="5" css={css`color: light-dark(var(--green-1000), var(--green-050))`}>How to Use Sensei</Heading>
              <List items={UsageItems} styleType="decimal" ordered={true} />
            </Stack>

            <Stack spacing="16px">
              <Heading level="5" css={css`color: light-dark(var(--green-1000), var(--green-050))`}>Why Choose Sensei?</Heading>
              <List items={ChooseItems} styleType="decimal" ordered={true} />
              <Body>Join us at Sensei and take your Japanese language skills to the next level with practical, situational phrases that will make you feel like a native speaker in no time. Whether you’re a beginner or an advanced learner, Sensei is here to guide you every step of the way.</Body>
            </Stack>

          </Stack>
        </Dialog>
      }

        <ActivityCard
          phrase="Konnichiwa, Sekai!" 
          translation='Hello, World!' 
          kanji="こんにちは、世界!"
          actions={
            <>
              <Responsive below={499}>
                <Stack spacing="16px" direction='horizontal'>
                  <Button variant='primary' size="S" onClick={categoriesToggleVisibility}>Get started</Button>
                  <Button variant='secondary' size="S" onClick={aboutToggleVisibility}>About</Button>
                </Stack>
              </Responsive>
              <Responsive above={500}>
                <Stack spacing="16px" direction='horizontal'>
                  <Button variant='primary' size="L" onClick={categoriesToggleVisibility}>Get started</Button>
                  <Button variant='secondary' size="L" onClick={aboutToggleVisibility}>About</Button>
                </Stack>
              </Responsive>
            </>
          }
        />
    </Offset>
  );
};

export default Home;