const variantActionStyles = {
  primary: {
    backgroundColor: 'light-dark(var(--action-primary-light), var(--action-primary-dark))',
    color: 'light-dark(var(--action-primary-text-light), var(--action-primary-text-dark))',
    hover: {
      backgroundColor: 'light-dark(var(--action-primary-hover-light), var(--action-primary-hover-dark))',
    },
  },
  secondary: {
    color: 'light-dark(var(--action-secondary-text-light), var(--action-secondary-text-dark))',
    backgroundColor: 'light-dark(var(--action-secondary-light), var(--action-secondary-dark))',
    boxShadow: 'inset 0 0 0 2px light-dark(var(--action-secondary-border-light), var(--action-secondary-border-dark))',
    hover: {
      backgroundColor: 'light-dark(var(--action-secondary-hover-light), var(--action-secondary-hover-dark))',
    },
  },
  tertiary: {
    color: 'light-dark(var(--action-tertiary-text-light), var(--action-tertiary-text-dark))',
    backgroundColor: 'light-dark(var(--action-tertiary-light), var(--action-tertiary-dark))',
    hover: {
      backgroundColor: 'light-dark(var(--action-tertiary-hover-light), var(--action-tertiary-hover-dark))',
    },
  },
  default: {
    color: 'light-dark(var(--green-800), var(--green-100))',
    backgroundColor: 'transparent',
    padding: 0,
    borderRadius: 0,
    fontFamily: 'sans-serif',
    'font-optical-sizing': 'auto',
    fontWeight: 600,
    fontStyle: 'normal',
    fontVariationSettings: '"wdth" 100',
    hover: {
      backgroundColor: 'transparent',
      color: 'light-dark(var(--green-800), var(--green-050))',
      boxShadow: '0 1px 0 0 light-dark(var(--green-800), var(--green-100))'
    },
  },
};

export default variantActionStyles;