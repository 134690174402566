import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/socialising/Anata-no-namae-wa-nan-desu-ka.mp3';
import sound2 from '../sound/socialising/Watashi-no-namae-wa-your-name-desu.mp3';
import sound3 from '../sound/socialising/Doko-kara-kimashita-ka.mp3';
import sound4 from '../sound/socialising/Watashi-wa-place-kara-kimashita.mp3';
import sound5 from '../sound/socialising/Oshigoto-wa-nan-desu-ka.mp3';
import sound6 from '../sound/socialising/Watashi-wa-job-desu.mp3';
import sound7 from '../sound/socialising/Nansai-desu-ka.mp3';
import sound8 from '../sound/socialising/Watashi-wa-age-sai-desu.mp3';
import sound9 from '../sound/socialising/Hima-na-toki-ni-nani-o-shimasu-ka.mp3';
import sound10 from '../sound/socialising/Watashi-wa-hima-na-toki-ni-activity-o-shimasu.mp3';
import sound11 from '../sound/socialising/Shumi-wa-nan-desu-ka.mp3';
import sound12 from '../sound/socialising/Renrakusaki-o-oshiete-kudasai.mp3';
import sound13 from '../sound/socialising/Tanoshikatta-desu.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';

import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Anata no namae wa nan desu ka?", translation: 'What is your name?', kanji: "あなたの名前は何ですか？", sound: sound1 },
  { phrase: "Watashi no namae wa [YourName] desu", translation: 'My name is [YourName]', kanji: "私の名前は[あなたの名前]です", sound: sound2 },
  { phrase: "Doko kara kimashita ka?", translation: 'Where are you from?', kanji: "どこから来ましたか？", sound: sound3 },
  { phrase: "Watashi wa [place] kara kimashita", translation: 'I am from [place]', kanji: "私は[場所]から来ました", sound: sound4 },
  { phrase: "Oshigoto wa nan desu ka?", translation: 'What do you do for work?', kanji: "お仕事は何ですか？", sound: sound5 },
  { phrase: "Watashi wa [job] desu", translation: 'I am a [job]', kanji: "私は[職業]です", sound: sound6 },
  { phrase: "Nansai desu ka?", translation: 'How old are you?', kanji: "何歳ですか？", sound: sound7 },
  { phrase: "Watashi wa [age] sai desu", translation: 'I am [age] years old', kanji: "私は[年齢]歳です", sound: sound8 },
  { phrase: "Hima na toki ni nani o shimasu ka?", translation: 'What do you do in your free time?', kanji: "暇な時に何をしますか？", sound: sound9 },
  { phrase: "Watashi wa hima na toki ni [activity] o shimasu", translation: 'I [activity] in my free time', kanji: "私は暇な時に[活動]をします", sound: sound10 },
  { phrase: "Shumi wa nan desu ka?", translation: 'What are your hobbies?', kanji: "趣味は何ですか？", sound: sound11 },
  { phrase: "Renrakusaki o oshiete kudasai?", translation: 'Can I have your contact information?', kanji: "連絡先を教えてください", sound: sound12 },
  { phrase: "Tanoshikatta desu", translation: 'I had a great time', kanji: "楽しかったです", sound: sound13 },
];

const Socialising = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

    // Add the 'no-scroll' class to the html element
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the html element on component unmount
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Socialising" />
      </Header>

      {sections.map((section) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Socialising;