import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/train-station/Eki-wa-doko-desu-ka.mp3';
import sound2 from '../sound/train-station/Kono-densha-wa-destination-ni-ikimasu-ka.mp3';
import sound3 from '../sound/train-station/Kippu-uriba-wa-doko-desu-ka.mp3';
import sound4 from '../sound/train-station/Ikura-desu-ka.mp3';
import sound5 from '../sound/train-station/Jikan-wa-nanji-desu-ka.mp3';
import sound6 from '../sound/train-station/Tsugi-no-densha-wa-nanji-desu-ka.mp3';
import sound7 from '../sound/train-station/Doko-de-norikae-masu-ka.mp3';
import sound8 from '../sound/train-station/Kippu-o-kudasai.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';
import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Eki wa doko desu ka?", translation: 'Where is the train station?', kanji: "駅はどこですか？", sound: sound1 },
  { phrase: "Kono densha wa [destination] ni ikimasu ka?", translation: 'Does this train go to [destination]?', kanji: "この電車は[目的地]に行きますか？", sound: sound2 },
  { phrase: "Kippu uriba wa doko desu ka?", translation: 'Where is the ticket counter?', kanji: "切符売り場はどこですか？", sound: sound3 },
  { phrase: "Ikura desu ka?", translation: 'How much is it?', kanji: "いくらですか？", sound: sound4 },
  { phrase: "Jikan wa nanji desu ka?", translation: 'What time is it?', kanji: "時間は何時ですか？", sound: sound5 },
  { phrase: "Tsugi no densha wa nanji desu ka?", translation: 'What time is the next train?', kanji: "次の電車は何時ですか？", sound: sound6 },
  { phrase: "Doko de norikae masu ka?", translation: 'Where do I transfer?', kanji: "どこで乗り換えますか？", sound: sound7 },
  { phrase: "Kippu o kudasai", translation: 'A ticket, please', kanji: "切符をください", sound: sound8 },
];

const TrainStation = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

    // Add the 'no-scroll' class to the html element
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the html element on component unmount
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Train Station" />
      </Header>

      {sections.map((section) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default TrainStation;