import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { SerializedStyles, css as cssImport } from '@emotion/react';

type Props = {
  level?: '1' | '2' | '3' | '4' | '5' | '6';
  children: ReactNode;
  css?: SerializedStyles;
};

const Heading: FC<Props> = ({ level = '1', children, css, ...props }) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  const Inner = styled(HeadingTag)<Props>(({ level = '1', css }) => cssImport(
    {
      color: 'light-dark(var(--dark), var(--light))',
      fontFamily: '"Aleo", serif',
      fontWeight: 700,
      fontOpticalSizing: 'auto',
      fontStyle: 'normal',
      margin: 0,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      ...(level === '1' && {
        fontSize: 72,
        '@media (min-width: 640px)': {
          fontSize: 96,
        },
      }),
      ...(level === '2' && {
        fontSize: 60,
        '@media (min-width: 640px)': {
          fontSize: 72,
        },
      }),
      ...(level === '3' && {
        fontSize: 48,
        '@media (min-width: 640px)': {
          fontSize: 60,
        },
      }),
      ...(level === '4' && {
        fontSize: 36,
        '@media (min-width: 640px)': {
          fontSize: 48,
        },
      }),
      ...(level === '5' && {
        fontSize: 24,
        '@media (min-width: 640px)': {
          fontSize: 36,
        },
      }),
      ...(level === '6' && {
        fontSize: 21,
        '@media (min-width: 640px)': {
          fontSize: 24,
        },
      }),
    },
    css,
  ));

  return (
    <Inner level={level} css={css} {...props}>
      {children}
    </Inner>
  );
};

export default Heading;