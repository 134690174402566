import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { SerializedStyles, css as cssImport } from '@emotion/react';

type Props = {
  children: ReactNode;
  css?: SerializedStyles;
};

const Body: FC<Props> = ({ children, css, ...props }) => {
  const Inner = styled.p<Props>(({ css }) => cssImport(
    {
      color: 'light-dark(var(--dark), var(--light))',
      fontFamily: '"Aleo", serif',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      margin: '0',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      '@media (min-width: 640px)': {
        fontSize: 18,
      },
    },
    css,
  ));

  return (
    <Inner css={css} {...props}>
      {children}
    </Inner>
  );
};

export default Body;