const baseActionStyles = {
    display: 'inline-block',
    textDecoration: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily: '"Aleo", serif',
    fontWeight: 700,
    'font-optical-sizing': 'auto',
    fontStyle: 'normal',
    fontSize: '24px',
    width: 'auto',
    height: 'auto',
    margin: '0',
    transition: 'background-color 0.3s, color 0.3s',
    padding: '16px 24px',
    border: 'none',
  };

export default baseActionStyles;