import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/shopping/Kore-wa-ikura-desu-ka.mp3';
import sound2 from '../sound/shopping/Kore-o-kudasai.mp3';
import sound3 from '../sound/shopping/Mou-sukoshi-yasuku-narimasen-ka.mp3';
import sound4 from '../sound/shopping/Kurejitto-kaado-wa-tsukaemasu-ka.mp3';
import sound5 from '../sound/shopping/Kokan-shite-mo-ii-desu-ka.mp3';
import sound6 from '../sound/shopping/Saizu-ga-arimasu-ka.mp3';
import sound7 from '../sound/shopping/Reji-wa-doko-desu-ka.mp3';
import sound8 from '../sound/shopping/Fukuro-wa-irimasen.mp3';
import sound9 from '../sound/shopping/Shichaku-dekimasu-ka.mp3';
import sound10 from '../sound/shopping/Kore-wa-seru-chu-desu-ka.mp3';
import sound11 from '../sound/shopping/Reshito-o-kudasai.mp3';
import sound12 from '../sound/shopping/Fukuro-o-kudasai.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';

import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Kore wa ikura desu ka?", translation: 'How much is this?', kanji: "これはいくらですか？", sound: sound1 },
  { phrase: "Kore o kudasai", translation: 'I’ll take this', kanji: "これをください", sound: sound2 },
  { phrase: "Mō sukoshi yasuku narimasen ka?", translation: 'Can you make it a bit cheaper?', kanji: "もう少し安くなりませんか？", sound: sound3 },
  { phrase: "Kurejitto kādo wa tsukaemasu ka?", translation: 'Can I use a credit card?', kanji: "クレジットカードは使えますか？", sound: sound4 },
  { phrase: "Kōkan shite mo ii desu ka?", translation: 'Can I exchange this?', kanji: "交換してもいいですか？", sound: sound5 },
  { phrase: "Saizu ga arimasu ka?", translation: 'Do you have this in my size?', kanji: "サイズがありますか？", sound: sound6 },
  { phrase: "Reji wa doko desu ka?", translation: 'Where is the cashier?', kanji: "レジはどこですか？", sound: sound7 },
  { phrase: "Fukuro wa irimasen", translation: 'I don’t need a bag', kanji: "袋はいりません", sound: sound8 },
  { phrase: "Shichaku dekimasu ka?", translation: 'Can I try this on?', kanji: "試着できますか？", sound: sound9 },
  { phrase: "Kore wa sēru-chū desu ka?", translation: 'Is this on sale?', kanji: "これはセール中ですか？", sound: sound10 },
  { phrase: "Reshīto o kudasai", translation: 'Can I have a receipt, please?', kanji: "レシートをください", sound: sound11 },
  { phrase: "Fukuro o kudasai", translation: 'Can I have a bag, please?', kanji: "袋をください", sound: sound12 },
];

const Shopping = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

     // Add the 'no-scroll' class to the html element
     document.documentElement.classList.add('no-scroll');
     document.body.classList.add('no-scroll');
 
     // Remove the 'no-scroll' class from the html element on component unmount
     return () => {
       document.documentElement.classList.remove('no-scroll');
       document.body.classList.remove('no-scroll');
     };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Shopping" />
      </Header>

      {sections.map((section) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Shopping;