import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/movies/Eiga-wa-nanji-ni-hajimarimasu-ka.mp3';
import sound2 from '../sound/movies/Kippu-wa-ikura-desu-ka.mp3';
import sound3 from '../sound/movies/Seki-wa-arimasu-ka.mp3';
import sound4 from '../sound/movies/Popcorn-o-kudasai.mp3';
import sound5 from '../sound/movies/Koko-ni-suwatte-mo-ii-desu-ka.mp3';
import sound6 from '../sound/movies/Eiga-wa-dou-deshita-ka.mp3';
import sound7 from '../sound/movies/Watashi-no-sukinna-eiga-wa-favorite-movie-desu.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';

import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Eiga wa nanji ni hajimarimasu ka?", translation: 'What time does the movie start?', kanji: "映画は何時に始まりますか？", sound: sound1 },
  { phrase: "Kippu wa ikura desu ka?", translation: 'How much is the ticket?', kanji: "切符はいくらですか？", sound: sound2 },
  { phrase: "Seki wa arimasu ka?", translation: 'Are there any seats available?', kanji: "席はありますか？", sound: sound3 },
  { phrase: "Popcorn o kudasai", translation: 'Popcorn, please', kanji: "ポップコーンをください", sound: sound4 },
  { phrase: "Koko ni suwatte mo ii desu ka?", translation: 'Is it okay to sit here?', kanji: "ここに座ってもいいですか？", sound: sound5 },
  { phrase: "Eiga wa dō deshita ka?", translation: 'How was the movie?', kanji: "映画はどうでしたか？", sound: sound6 },
  { phrase: "Watashi no sukinna eiga wa [favorite movie] desu", translation: 'My favorite movie is [favorite movie]', kanji: "私の好きな映画は[お気に入りの映画]です", sound: sound7 },
];

const Movies = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

    // Add the 'no-scroll' class to the html element
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the html element on component unmount
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Movies" />
      </Header>

      {sections.map((section, index) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Movies;