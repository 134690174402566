import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Mouse from './icons/mouse';

const Pulse = keyframes`
  0% { opacity: 30%; }
  45% { opacity: 100%; }
  65% { opacity: 100%; }
  100% { opacity: 30%; }
`;

const Load = keyframes`
  0% { transform: translate3d(0, 100px, 0); }
  100% { transform: translate3d(0px, 0px, 0); }
`;

const Out = keyframes`
  0% { transform: translate3d(0, 100px, 0); opacity: 100%}
  100% { transform: translate3d(0px, 0px, 0); opacity: 0%}
`;

const El = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  animation: `${Pulse} 3s ease-in-out infinite, ${Load} 1s ease-in-out`,
  position: 'fixed',
  bottom: 'calc(24px + env(safe-area-inset-bottom))',
  left: 'calc(50% - (38px / 2))',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'center',
  pointerEvents: 'none',
  fontFamily: '"Aleo", serif',
  fontWeight: 400,
  color: 'light-dark(var(--green-300), var(--green-700))',
  'font-optical-sizing': 'auto',
  fontStyle: 'normal',
  ...(!isVisible && {
    opacity: !isVisible ? 0 : 1,
    animation: `${Out} 1s ease-in-out initial`,
  }),
}));

const DesktopIcon = styled.div({
  display: 'none',
  '@media (pointer: fine)': {
    display: 'flex'
  }
});

interface ScrollProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

export const Scroll: FC<ScrollProps> = ({ containerRef }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const isBottom =
          containerRef.current.scrollHeight - containerRef.current.scrollTop ===
          containerRef.current.clientHeight;
        setIsVisible(!isBottom);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerRef]);

  return (
    <El isVisible={isVisible}>
      <DesktopIcon>
        <Mouse />
      </DesktopIcon>
      <span>Scroll</span>
    </El>
  );
};

export default Scroll;