/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface ListProps {
  items: string[];
  className?: string;
  styleType?: 'disc' | 'circle' | 'square' | 'decimal' | 'lower-alpha' | 'upper-alpha' | 'lower-roman' | 'upper-roman';
  ordered?: boolean;
}

const baseListStyle = (styleType: string) => css`
  list-style-type: ${styleType};
  margin: 0;
  padding: 0;
  padding-left: 20px;
  color: light-dark(var(--dark), var(--light));
  font-family: "Aleo", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: 640px): {
    font-size: 18px;
  }
  li {
    margin-bottom: 12px;
  }
  li:last-child {
    margin: 0;
  }
`;

const List: React.FC<ListProps> = ({ items, className, styleType = 'disc', ordered = false }) => {
  const ListTag = ordered ? 'ol' : 'ul';
  return (
    <ListTag css={baseListStyle(styleType)} className={className}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ListTag>
  );
};

export default List;