import React from "react";

function Mouse() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="40"
      fill="none"
      viewBox="0 0 24 40"
    >
      <rect width="1" height="6" x="11" y="8" fill="currentColor" rx="0.5"></rect>
      <rect width="22" height="38" x="0.5" y="0.5" stroke="currentColor" rx="11"></rect>
    </svg>
  );
}

export default Mouse;
