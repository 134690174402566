import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/restaurants/Menyuu-o-kudasai.mp3';
import sound2 from '../sound/restaurants/Osusume-wa-nan-desu-ka.mp3';
import sound3 from '../sound/restaurants/Kore-o-kudasai.mp3';
import sound4 from '../sound/restaurants/Mizu-o-onegaishimasu.mp3';
import sound5 from '../sound/restaurants/Gochisousama-deshita.mp3';
import sound6 from '../sound/restaurants/Yoyaku-o-shita-no-desu-ga.mp3';
import sound7 from '../sound/restaurants/Kaikei-o-onegaishimasu.mp3';
import sound8 from '../sound/restaurants/Chumon-o-onegaishimasu.mp3';
import sound9 from '../sound/restaurants/Oishii-desu.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';

import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Menyū o kudasai", translation: 'Can I have the menu, please?', kanji: "メニューをください", sound: sound1 },
  { phrase: "Osusume wa nan desu ka?", translation: 'What do you recommend?', kanji: "おすすめは何ですか？", sound: sound2 },
  { phrase: "Kore o kudasai", translation: 'I\'ll have this, please', kanji: "これをください", sound: sound3 },
  { phrase: "Mizu o onegaishimasu", translation: 'Water, please', kanji: "水をお願いします", sound: sound4 },
  { phrase: "Gochisōsama deshita", translation: 'Thank you for the meal', kanji: "ごちそうさまでした", sound: sound5 },
  { phrase: "Yoyaku o shita no desu ga", translation: 'I have a reservation', kanji: "予約をしたのですが", sound: sound6 },
  { phrase: "Kaikei o onegaishimasu", translation: 'Check, please', kanji: "会計をお願いします", sound: sound7 },
  { phrase: "Chūmon o onegaishimasu", translation: 'I would like to order', kanji: "注文をお願いします", sound: sound8 },
  { phrase: "Oishii desu", translation: 'It is delicious', kanji: "美味しいです", sound: sound9 },
];

const Restaurants = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

     // Add the 'no-scroll' class to the html element
     document.documentElement.classList.add('no-scroll');
     document.body.classList.add('no-scroll');
 
     // Remove the 'no-scroll' class from the html element on component unmount
     return () => {
       document.documentElement.classList.remove('no-scroll');
       document.body.classList.remove('no-scroll');
     };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Restaurants" />
      </Header>

      {sections.map((section) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Restaurants;