/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import Dialog from '../components/dialog';
import Stack from '../components/stack';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

interface CategoryDialogProps {
  onClick?: () => void;
}

const ButtonKanji = css`
width: 100%;
height: auto;
flex-direction: row;
justify-content: center;
font-size: 30px;
color: light-dark(var(--green-700), var(--green-500));
font-family: "M PLUS Rounded 1c", sans-serif;
font-weight: 500; 
font-style: normal;
@media (min-width: 640px) {
  font-size: 48px;
}
`;

const ButtonIcon = css`
width: 100%;
height: auto;
flex-direction: row;
justify-content: center;
font-size: 36px;
color: light-dark(var(--green-700), var(--green-500));
font-family: "M PLUS Rounded 1c", sans-serif;
font-weight: 500; 
font-style: normal;
@media (min-width: 640px) {
  font-size: 48px;
}
`;

const ButtonText = css`
padding: 16px 0;
color: light-dark(var(--green-1000), var(--green-050));
font-size: 18px;
@media (min-width: 640px) {
  font-size: 24px;
}
`;

const CategoryLink = styled(Link)`
text-decoration: none;
padding: 16px 24px;
text-align: center;
font-family: "Aleo", serif;
font-weight: 700;
font-optical-sizing: auto;
font-style: normal;
border-radius: 8px;
&:hover {
  background: light-dark(var(--action-tertiary-hover-light), var(--action-tertiary-hover-dark));
}

`;

const GridStyles = css`
grid-template-columns:  1fr;
column-gap: 24px;
row-gap: 36px;
@media (min-width: 360px) {
  grid-template-columns:  1fr 1fr;
  column-gap: 24px;
  row-gap: 36px;
}
@media (min-width: 430px) {
  grid-template-columns:  1fr 1fr;
  gap: 36px;
}
@media (min-width: 900px) {
  grid-template-columns:  1fr 1fr 1fr;
}
@media (min-width: 1280px) {
  grid-template-columns:  1fr 1fr 1fr 1fr;
}
`;

const CategoryDialog: React.FC<CategoryDialogProps> = ({ onClick }) => {
  return (
    <Dialog title="Select a category" onClick={onClick}>
      <Stack variant='grid' css={GridStyles}>
        <CategoryLink to="/greetings">
          <Stack>
            <Stack css={ButtonKanji}>挨拶</Stack>
            <Stack css={ButtonText}>Greetings</Stack>
            <Stack css={ButtonIcon}>🙋‍♂️🙋‍♀️</Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/restaurants">
          <Stack>
            <Stack css={ButtonKanji}>食事</Stack>
            <Stack css={ButtonText}>Restaurant</Stack>
            <Stack css={ButtonIcon}>🍣🍜</Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/movies">
          <Stack>
            <Stack css={ButtonKanji}>映画</Stack>
            <Stack css={ButtonText}>Movies</Stack>
            <Stack css={ButtonIcon}>🎬🍿</Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/train-station">
          <Stack>
            <Stack css={ButtonKanji}>駅</Stack>
            <Stack css={ButtonText}>Train Station</Stack>
            <Stack css={ButtonIcon}>🚉🗺️</Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/shopping">
          <Stack>
            <Stack css={ButtonKanji}>買い物</Stack>
            <Stack css={ButtonText}>Shopping</Stack>
            <Stack css={ButtonIcon}><span>🛒</span><span>🛍️</span></Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/travel">
          <Stack>
            <Stack css={ButtonKanji}>旅行</Stack>
            <Stack css={ButtonText}>Travel</Stack>
            <Stack css={ButtonIcon}>🌏✈️</Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/emergency">
          <Stack>
            <Stack css={ButtonKanji}>緊急</Stack>
            <Stack css={ButtonText}>Emergency</Stack>
            <Stack css={ButtonIcon}>🚨🔴</Stack>
          </Stack>
        </CategoryLink>
        <CategoryLink to="/socialising">
          <Stack>
            <Stack css={ButtonKanji}>交流</Stack>
            <Stack css={ButtonText}>Socialising</Stack>
            <Stack css={ButtonIcon}>🗣️👫</Stack>
          </Stack>
        </CategoryLink>
      </Stack>
    </Dialog>
  );
};

export default CategoryDialog;