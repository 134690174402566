/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import variantAction from './helpers/variant-action';
import sizeActionStyles from './helpers/size-actions';
import baseAction from './helpers/base-action';

interface LinkProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'default';
  size?: 'S' | 'M' | 'L';
  href: string;
  children: React.ReactNode;
  width?: string; // Width of the link
  height?: string; // Height of the link
  margin?: string; // Margin around the link
  style?: React.CSSProperties; // Additional inline styles
}

interface RouterLinkProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'default';
  size?: 'S' | 'M' | 'L';
  children: React.ReactNode;
  width?: string; // Width of the link
  height?: string; // Height of the link
  margin?: string; // Margin around the link
  style?: React.CSSProperties; // Additional inline styles
  to: string;
}

const getLinkStyles = (
  variant: 'primary' | 'secondary' | 'tertiary' | 'default', 
  size: 'S' | 'M' | 'L', 
  style?: React.CSSProperties
) => {
  const baseStyle = {
    ...baseAction,
    ...sizeActionStyles[size],
    ...variantAction[variant],
    ...style,
  };

  const hoverStyle = variantAction[variant].hover;

  return css({
    ...baseStyle,
    ':hover': hoverStyle,
  });
};

const AnchorLink = styled.a<LinkProps>`
  ${({ variant = 'default', size = 'M', width, height, margin, style }) => css`
    ${getLinkStyles(variant, size, style)}
    width: ${width || 'auto'};
    height: ${height || 'auto'};
    margin: ${margin || '0'};
  `}
`;

export const RouterLink = styled(Link)<RouterLinkProps>`
  ${({ variant = 'default', size = 'M', width, height, margin, style }) => css`
    ${getLinkStyles(variant, size, style)}
    width: ${width || 'auto'};
    height: ${height || 'auto'};
    margin: ${margin || '0'};
  `}
`;

export default AnchorLink;