import { useState, useCallback, useRef, useEffect } from 'react';
import Header from '../components/header';
import ActivityCard from '../compositions/Activity';
import Breadcrumb from '../components/breadcrumb';
import CategoryDialog from '../compositions/CategoryDialog';

import sound1 from '../sound/travel/Yoyaku-o-onegaishimasu.mp3';
import sound2 from '../sound/travel/Hoteru-wa-doko-desu-ka.mp3';
import sound3 from '../sound/travel/Takushii-o-yonde-kudasai.mp3';
import sound4 from '../sound/travel/Kuukou-wa-doko-desu-ka.mp3';
import sound5 from '../sound/travel/Michi-ni-mayoimashita.mp3';
import sound6 from '../sound/travel/Chikaku-ni-yuumeina-basho-wa-arimasu-ka.mp3';
import sound7 from '../sound/travel/Toire-wa-doko-desu-ka.mp3';
import sound8 from '../sound/travel/Omiyage-o-kai-ni-ikitai-desu.mp3';
import sound9 from '../sound/travel/Chekkuin-onegaishimasu.mp3';
import sound10 from '../sound/travel/Wi-Fi-wa-arimasu-ka.mp3';
import sound11 from '../sound/travel/Kono-jusho-made-onegaishimasu.mp3';
import sound12 from '../sound/travel/Kanko-annaijo-wa-doko-desu-ka.mp3';
import { ScrollSnap } from '../components/helpers/scroll-snap';
import Scroll from '../components/scroll';

interface Section {
  phrase: string;
  translation: string;
  kanji: string;
  sound: string;
}

const sections: Section[] = [
  { phrase: "Yoyaku o onegaishimasu", translation: 'I would like to make a reservation', kanji: "予約をお願いします", sound: sound1 },
  { phrase: "Hoteru wa doko desu ka?", translation: 'Where is the hotel?', kanji: "ホテルはどこですか？", sound: sound2 },
  { phrase: "Takushii o yonde kudasai", translation: 'Please call a taxi', kanji: "タクシーを呼んでください", sound: sound3 },
  { phrase: "Kuukou wa doko desu ka?", translation: 'Where is the airport?', kanji: "空港はどこですか？", sound: sound4 },
  { phrase: "Michi ni mayoimashita", translation: 'I am lost', kanji: "道に迷いました", sound: sound5 },
  { phrase: "Chikaku ni yuumeina basho wa arimasu ka?", translation: 'Are there any famous places nearby?', kanji: "近くに有名な場所はありますか？", sound: sound6 },
  { phrase: "Toire wa doko desu ka?", translation: 'Where is the restroom?', kanji: "トイレはどこですか？", sound: sound7 },
  { phrase: "Omiyage o kai ni ikitai desu", translation: 'I want to go buy souvenirs', kanji: "お土産を買いに行きたいです", sound: sound8 },
  { phrase: "Chekkuin onegaishimasu", translation: 'Check-in, please', kanji: "チェックインお願いします", sound: sound9 },
  { phrase: "Wi-Fi wa arimasu ka?", translation: 'Is there Wi-Fi?', kanji: "Wi-Fiはありますか？", sound: sound10 },
  { phrase: "Kono jūsho made onegaishimasu", translation: 'Please take me to this address', kanji: "この住所までお願いします", sound: sound11 },
  { phrase: "Kankō annaijo wa doko desu ka?", translation: 'Where is the tourist information center?', kanji: "観光案内所はどこですか？", sound: sound12 },
];

const Travel = () => {
  const [categoriesIsVisible, categoriesSetIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null); // Add a ref for the container

  const categoriesToggleVisibility = useCallback(() => {
    categoriesSetIsVisible(prevState => !prevState);
  }, []);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Call scrollToTop on component mount
  useEffect(() => {
    scrollToTop();

    // Add the 'no-scroll' class to the html element
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the html element on component unmount
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <ScrollSnap
      ref={containerRef}  // Attach the ref to the container
    >
      {categoriesIsVisible && 
        <CategoryDialog onClick={categoriesToggleVisibility} />
      }

      <Header onClick={categoriesToggleVisibility}>
        <Breadcrumb title="Travel" />
      </Header>

      {sections.map((section) => (
        <ActivityCard
          key={section.phrase}
          phrase={section.phrase} 
          translation={section.translation} 
          kanji={section.kanji}
          source={section.sound}
        />
      ))}

      <Scroll containerRef={containerRef} />

    </ScrollSnap>
  );
};

export default Travel;