// Dialog.tsx
import React from 'react';
import styled from '@emotion/styled';
import Heading from './heading';
import Button from './button';
import { css } from '@emotion/react';
import Responsive from './responsive';

interface DialogProps {
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  onClick?: () => void;
}

const Outer = styled.div({
  width: '100%',
  height: '100%',
  background: 'light-dark(var(--light), var(--dark));',
  position: 'fixed',
  top: '0',
  left: '0',
  overflowY: 'scroll',
  zIndex: 1
});


const Header = styled.div({
  padding: 24,
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  left: 0,
  top: 0,
  height: 74,
  background: 'light-dark(var(--light), var(--dark));',
  borderBottom: '1px solid light-dark(var(--green-200), var(--green-800))',
  zIndex: 1,
  '@media (min-width: 640px)': {
    height: 76,
  },
});

const Child = styled.div({
  padding: 24,
  paddingTop: 74 + 24,
  paddingBottom: 'calc(74px + 24px + env(safe-area-inset-bottom))',
  '@media (min-width: 500px)': {
    paddingBottom: 'calc(98px + 24px + env(safe-area-inset-bottom))',
  },
  '@media (min-width: 640px)': {
    paddingTop: 76 + 48,
    paddingBottom: 'calc(102px + 24px + env(safe-area-inset-bottom))',
  },
});

const Actions = styled.div({
  position: 'fixed',
  width: '100%',
  left: 0,
  bottom: 0,
  background: 'light-dark(var(--light), var(--dark));',
  borderTop: '1px solid light-dark(var(--green-200), var(--green-800))',
  zIndex: 1
});

const ActionsInner = styled.div({
  display: 'flex',
  padding: 'calc(16px)',
  paddingBottom: 'calc(16px + env(safe-area-inset-bottom))',
  gap: 16,
  '@media (min-width: 500px)': {
    gap: 24,
    paddingTop: 24,
  },
});

const dialogHeading = css`
  color: light-dark(var(--dark), var(--light));
  text-align: center;
`;
  
const Dialog: React.FC<DialogProps> = ({title, children, onClick, actions}) => {
    return (
      <Outer>
        <Header>{title && <Heading level="6" css={dialogHeading}>{title}</Heading>}</Header>
        <Child>{children}</Child>
        <Actions>
          <Responsive below={499}>
            <ActionsInner>
              <Button 
                variant='primary' 
                size="S" 
                onClick={onClick} 
                width='100%'>
                  Close
              </Button>
              {actions && actions}
            </ActionsInner>
          </Responsive>
          <Responsive above={500}>
            <ActionsInner>
              <Button 
                variant='primary' 
                size="M" 
                onClick={onClick} 
                width='100%'>
                  Close
              </Button>
              {actions && actions}
            </ActionsInner>
          </Responsive>
        </Actions>
      </Outer>
    );
};

export default Dialog;