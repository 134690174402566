import styled from '@emotion/styled';
import Stack from '../components/stack';
import { RouterLink } from './anchor';

interface BreadcrumbsProps {
  title?: string;
}

const BreadcrumbStyles = styled(Stack)({
  borderColor: 'light-dark(var(--surface-dark), var(--surface-light))',
  background: 'light-dark(var(--light), var(--dark));',
  borderBottom: '1px solid light-dark(var(--green-200), var(--green-800))',
  padding: '16px 24px 16px 24px',
  '@media (min-width: 640px)': {
    padding: '16px 36px 16px 36px',
  },
});

const Breadcrumb: React.FC<BreadcrumbsProps> = ({ title }) => {
  return (
    <BreadcrumbStyles direction='horizontal' spacing="6px">
      <RouterLink size="S" to="/">Home</RouterLink>
      <span style={{color: 'light-dark(var(--green-1000), var(--green-050))'}}>&#8702;</span>
      <span style={{color: 'light-dark(var(--green-1000), var(--white))'}}>{title}</span>
    </BreadcrumbStyles>
  );
};

export default Breadcrumb;