const sizeActionStyles = {
  S: {
    padding: '12px 16px',
    fontSize: '14px',
    borderRadius: '4px',
    '@media (min-width: 640px)': {
      fontSize: '16px',
    },
  },
  M: {
    padding: '16px 24px',
    fontSize: '21px',
    borderRadius: '8px',
    '@media (min-width: 640px)': {
      fontSize: '24px',
    },
  },
  L: {
    padding: '24px 36px',
    fontSize: '21px',
    '@media (min-width: 640px)': {
      fontSize: '24px',
    },
  },
};

export default sizeActionStyles;