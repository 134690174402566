import styled from "@emotion/styled";

export const ScrollSnap = styled.div({
  scrollSnapType: 'y mandatory',
  overflowY: 'scroll',
  height: 'calc(100% - 124px)',
  marginTop: 124,
  '@media (min-width: 640px)': {
    height: 'calc(100% - 128px)',
    marginTop: 128,
  },
});